import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { MaterialReactTable } from "material-react-table";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { getStatusColor } from "../../../utils/parcelStatusColor";
import { getInternalStatusColor } from "../../../utils/shipmentInternalStatusColor";
import { Link, useParams } from "react-router-dom";
import { formatDateInLocal } from "../../../utils/dateFormat";
import AddIcon from "@mui/icons-material/Add";

export default function InboundShipmentsTable() {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const param = useParams();

  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const [filter, setFilter] = useState({
    status: param?.filter ? capitalizeFirstLetter(param.filter) : null,
    limit: pagination.pageSize,
    page: pagination.pageIndex,
    filter: "",
  });

  useEffect(() => {
    if (pagination.pageIndex || pagination.pageSize) {
      setFilter((prev) => ({
        status: param?.filter ? capitalizeFirstLetter(param.filter) : null,
        limit: pagination.pageSize,
        page: pagination.pageIndex,
        filter: prev.filter,
      }));
    }
  }, [pagination, param]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(!data.length);
      setIsRefetching(!!data.length);

      try {
        console.log(filter);
        const result = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/inbound/shipment/table",
          {
            status: filter.status,
            limit: filter.limit,
            page: filter.page,
            filter: filter.filter,
          }
        );

        setData(result.data.data.shipments);
        setRowCount(result.data.data.totalCount);
      } catch (err) {
        if (err.response?.status === 404) {
          setData([]);
          setRowCount(0);
        } else {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
        setIsRefetching(false);
      }
    };

    fetchData();
  }, [filter, columnFilters, sorting]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "open",
        header: "Open",
        size: 0.5,
        Cell: ({ cell, row }) => (
          <Link to={"/parcels/inbound/" + row.original.AWB} target="_blank">
            <IconButton>
              <OpenInNewIcon fontSize="small" color="primary" />
            </IconButton>
          </Link>
        ),
      },
      {
        accessorKey: "AWB",
        header: "AWB",
        size: 0.6,
      },

      {
        accessorKey: "shipperName",
        header: "Shipper",
        size: 0.6,
      },
      {
        accessorKey: "consigneeName",
        header: "Consignee",
        size: 0.6,
      },
      {
        accessorKey: "consigneePhone",
        header: "Consignee Phone",
        size: 0.6,
      },

      {
        accessorKey: "consigneeAddress",
        header: "Consignee Address",
        size: 0.6,
      },
      {
        accessorKey: "location",
        header: "Location",
        size: 0.6,
      },
      {
        accessorKey: "carrier",
        header: "Carrier",
        size: 0.6,
      },

      {
        accessorKey: "updatedAt",
        header: "Update Date",
        Cell: ({ cell }) => <>{formatDateInLocal(cell.getValue())}</>,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 2,
        Cell: ({ cell }) => (
          <Box
            sx={() => ({
              borderRadius: "0.25rem",
              color: "#fff",
              p: "0.25rem",
              maxWidth: "30ch",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
            })}
            className={getStatusColor(cell.getValue().split(" ")[0])}
          >
            {cell.getValue().toUpperCase()}
          </Box>
        ),
      },
      {
        id: "internalStatus",
        accessorKey: "internalStatus",
        header: "Internal Status",
        size: 0.6,
        Cell: ({ cell }) => (
          <Box
            sx={() => ({
              borderRadius: "0.25rem",
              color: "#fff",

              p: "0.25rem",
              textAlign: "center",
            })}
            className={getInternalStatusColor(cell.getValue())}
          >
            {cell.getValue().toUpperCase()}
          </Box>
        ),
      },
      {
        accessorKey: "createdAt",
        header: "Booking Date",
        Cell: ({ cell }) => <>{formatDateInLocal(cell.getValue())}</>,
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      getRowId={(row) => row.phoneNumber}
      initialState={{ showColumnFilters: false }}
      manualFiltering
      manualPagination
      enableStickyHeader
      enableStickyFooter
      enableRowSelection={false}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableToolbarInternalActions={false}
      positionGlobalFilter="right"
      renderTopToolbarCustomActions={(table) => {
        return (
          <div className="p-4">
            <Typography variant="h5">Inbound Shipments</Typography>
          </div>
        );
      }}
      renderBottomToolbarCustomActions={(table) => {
        return (
          <Button variant="contained" endIcon={<AddIcon />}>
            <Link target="_blank" to={"/parcels/inbound/new"}>
              Book Shipment
            </Link>
          </Button>
        );
      }}
      muiSearchTextFieldProps={{
        placeholder: "Search inbound shipments",
        sx: { minWidth: "300px" },
        variant: "outlined",

        size: "small",
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      muiTableContainerProps={{
        sx: { height: "60vh" },
      }}
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={(a) => {
        if (!a) {
          setFilter({
            status: param?.filter ? capitalizeFirstLetter(param.filter) : null,
            limit: "",
            page: "",
            filter: "",
          });
          setPagination({
            pageIndex: 0,
            pageSize: 10,
          });
        } else setFilter({ status: "", limit: "", page: "", filter: a });
      }}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={rowCount}
      state={{
        columnFilters,

        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        showGlobalFilter: true,
        density: "compact",
        sorting,
      }}
    />
  );
}
